import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Link} from "react-router-dom";

const dataList = [
    {
        image: '/images/service/serviice-01.jpg',
        title: 'Awarded Design',
        description: 'There are many variations variats  of passages of Lorem Ipsum available.'
    },
    {
        image: '/images/service/serviice-02.jpg',
        title: 'Design & Creative',
        description: 'Passages there are many variatin Lorem Ipsum available have suffered.'
    }
]

const dataList2 = [
    {
        image: '/images/service/serviice-03.jpg',
        title: 'App Development',
        description: 'There are many variations variats  of passages of Lorem Ipsum available.'
    },
    {
        image: '/images/service/serviice-04.jpg',
        title: 'Business Design',
        description: 'Passages there are many variatin Lorem Ipsum available have suffered.'
    }
]

const dataList3 = [
    {
        image: '/images/service/serviice-03.jpg',
        title: 'App Development',
        description: 'There are many variations variats  of passages of Lorem Ipsum available.'
    },
    {
        image: '/images/service/serviice-04.jpg',
        title: 'Business Design',
        description: 'Passages there are many variatin Lorem Ipsum available have suffered.'
    },
    {
        image: '/images/service/serviice-04.jpg',
        title: 'Business Design',
        description: 'Passages there are many variatin Lorem Ipsum available have suffered.'
    }
]

const TabThree = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-12">
                                    <div className="rn-default-tab style-three">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>WaafiPay</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>MasterCard</button>
                                                    </div>   
                                                </Tab>
                                              
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <div className="row align-items-center row--10">
                                                            <div className="col-lg-4">
                                                                <div className="section-title">
                                                                    <h5 className="title">Pay With Evc+,Somnet,Sahal,Golis</h5>
                                                                    <form method="post" >
            <div className="form-group">
            <input 
                type="hidden" 
                name="CompetitorId"
                value={1}
                />
                <input style={{color:'#000'}}
                type="number" min="1"
                name="NumberOfVotes"
                placeholder="Number of Votes"
                required
               
                />
            </div>

            <div className="form-group">
                <input style={{color:'#000'}}
                type="number" min="1"
                name="phone"
                placeholder="Phone Number"
                required
               
                />
            </div>
              <div className="form-group">
                <button type="submit" className="btn-default btn-small"> 
                submit
                 </button>
            </div> 
            
        </form>
                                                                </div>
                                                            </div>
                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <div className="row align-items-center row--10">
                                                            <div className="col-lg-4">
                                                                <div className="section-title">
                                                                    <h4 className="title">Pay With Mastercard</h4>
                                                                    <form method="post" >
            <div className="form-group">
            <input 
                type="hidden" 
                name="CompetitorId"
                value={1}
                />
                <input style={{color:'#000'}}
                type="number" min="1"
                name="NumberOfVotes"
                placeholder="Number of Votes"
                required
               
                />
            </div>

            <div className="form-group">
                <input style={{color:'#000'}}
                type="number" min="1"
                name="phone"
                placeholder="Phone Number"
                required
               
                />
            </div>
              <div className="form-group">
                <button type="submit" className="btn-default btn-small"> 
                submit
                 </button>
            </div> 
            
        </form>
                                                                </div>
                                                            </div>
                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabThree
