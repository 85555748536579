import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import axios from 'axios';

import  '../team/skleton.css';
const SkeletonScreen = () => {
    return (
        <div className='flex'>
      <section className="box">
        <div className="skeleton-placeholder" />
        <div className="skeleton-placeholder" />
        <div className="skeleton-placeholder" />
       </section>
       <section className="box">
        <div className="skeleton-placeholder" />
        <div className="skeleton-placeholder" />
        <div className="skeleton-placeholder" />
       </section>
       <section className="box">
        <div className="skeleton-placeholder" />
        <div className="skeleton-placeholder" />
        <div className="skeleton-placeholder" />
       </section>
       <section className="box">
        <div className="skeleton-placeholder" />
        <div className="skeleton-placeholder" />
        <div className="skeleton-placeholder" />
       </section>
     </div>
    )
  }
const ServiceTwo = ({textAlign, cardStyle}) => {
  
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchInfo = () => {
    setIsLoading(true);
    try {
      return axios.get(process.env.REACT_APP_AllCategory).then((res) => setData(res.data)).finally(() => setIsLoading(false));;
     } catch(err) {
        setIsLoading(false);
        console.log(err);
    } 
    };

  useEffect(() => {
    fetchInfo();

  }, []);
  if (isLoading)
  return (
      <div>
        <center>  <SkeletonScreen /></center>
      </div>
  );
    return (
        <div className="row row--15 service-wrapper">
              {data.map( (val , i) => (
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                      <Link to={process.env.PUBLIC_URL +`/competitorsByCategory/${val.id}`}>   <div  className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner" >
                                
                                <div className="content">
                                    <h4 style={{textTransform:'capitalize'}} className="title mb--20"><Link to={process.env.PUBLIC_URL +`/competitorsByCategory/${val.id}`} dangerouslySetInnerHTML={{__html: val.CategoryName}}></Link></h4>
                                    {/* <Link className="btn-default btn-small btn-border" to={process.env.PUBLIC_URL +`/competitorsByCategory/${val.id}`}> Competitors</Link> */}
                                </div>
                            </div>
                        </div></Link>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
} 
export default ServiceTwo;
