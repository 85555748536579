import React, { useEffect, useState } from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import Separator from "../separator/Separator";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import TeamOne from "./TeamOne";
import axios from 'axios';
import  './skleton.css';
const SkeletonScreen = () => {
    return (
        <div className='flex'>
      <section className="box">
        <div className="profile-img" />
        <div className="skeleton-placeholder" />
        <div className="skeleton-placeholder" />
    <div className="skeleton-placeholder" />
        <div className="skeleton-placeholder" />
      </section>
      <section className="box">
      <div className="profile-img" />
      <div className="skeleton-placeholder" />
      <div className="skeleton-placeholder" />
    <div className="skeleton-placeholder" />
      <div className="skeleton-placeholder" />
    </section>
    <section className="box">
    <div className="profile-img" />
    <div className="skeleton-placeholder" />
    <div className="skeleton-placeholder" />
    <div className="skeleton-placeholder" />
    <div className="skeleton-placeholder" />
  </section></div>
    )
  }
const competitors = ({match: {params: {id}}}) => {
    const CmId = parseInt(id, 10)
    const url = process.env.REACT_APP_CMPBYCAT+CmId;
  const [data, setData] = useState([]);
  const [Ctn, setCtn] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
 

  useEffect(() => {
    const fetchInfo = async ()  => {
      try {
        setIsLoading(true);
      return axios.get(url).then((res) =>
      { setData(res.data);
          if(res.data.length > 0) setCtn(res.data[0].CategoryName);}
      ).finally(() => setIsLoading(false));;
    }catch(err) {
      console.log(err);
  } 
  }
    fetchInfo();

  }, []);
 
    return (
        <>
            <SEO title="Competitors || Somali Night Awards" />
            <Layout>
               
                <div className="main-content">

                    {/* Start Elements Area  */}
                    <div className="rwt-team-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle style={{textTransform:'capitalize'}}
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = {Ctn.length ? `Competitors Of` : ''}
                                            title = {`${Ctn}`}
                                            description = ""
                                        />
                                </div>
                            </div>
                            {isLoading ?  <center> <SkeletonScreen /> </center> :  
                            <TeamOne data={data} CmId={CmId} column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="team-style-default" />}
                        </div>
                    </div>
                    {/* End Elements Area  */}
                    
              
                </div>
            </Layout>
        </>
    )
}
export default competitors;