import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import GalleryOne from "./GalleryOne";


const PopupData = [
    {
        id: "01",
        image: "./images/Gallery/1.png",
        popupLink: [
            './images/Gallery/1.png',
        ],
    },
   
    {
        id: "03",
        image: "./images/Gallery/2.png",
        popupLink: [
            './images/Gallery/2.png',
        ],
    }, {
        id: "01",
        image: "./images/Gallery/3.png",
        popupLink: [
            './images/Gallery/3.png',
        ],
    },
    {
        id: "02",
        image: "./images/Gallery/4.png",
        popupLink: [
            './images/Gallery/4.png',
        ],
    },
    {
        id: "03",
        image: "./images/Gallery/5.png",
        popupLink: [
            './images/Gallery/5.png',
        ],
    },
    {
        id: "01",
        image: "./images/Gallery/6.png",
        popupLink: [
            './images/Gallery/6.png',
        ],
    },
    {
        id: "02",
        image: "./images/Gallery/7.png",
        popupLink: [
            './images/Gallery/7.png',
        ],
    },
    {
        id: "03",
        image: "./images/Gallery/8.png",
        popupLink: [
            './images/Gallery/8.png',
        ],
    },
    {
        id: "01",
        image: "./images/Gallery/9.png",
        popupLink: [
            './images/Gallery/9.png',
        ],
    },
    {
        id: "02",
        image: "./images/Gallery/10.png",
        popupLink: [
            './images/Gallery/10.png',
        ],
    },
   
]
const PopupHm = [
    {
        id: "01",
        image: "./images/portfolio/w1.png",
        popupLink: [
            './images/portfolio/w1.png',
           
        ],
    },
    {
        id: "02",
        image: "./images/portfolio/w2.png",
        popupLink: [
            './images/portfolio/w2.png',
        ],
    },
    {
        id: "03",
        image: "./images/portfolio/w3.png",
        popupLink: [
            './images/portfolio/w3.png',
        ],
    },
    {
        id: "04",
        image: "./images/portfolio/w4.png",
        popupLink: [
            './images/portfolio/w4.png',
        ],
    },
]
const PopupData2 = [
    {
        id: "01",
        image: "./images/portfolio/w1.png",
        popupLink: [
            './images/portfolio/w1.png',
           
        ],
    },
    {
        id: "02",
        image: "./images/portfolio/w2.png",
        popupLink: [
            './images/portfolio/w2.png',
        ],
    },
    {
        id: "03",
        image: "./images/portfolio/w3.png",
        popupLink: [
            './images/portfolio/w3.png',
        ],
    },
    {
        id: "04",
        image: "./images/portfolio/w4.png",
        popupLink: [
            './images/portfolio/w4.png',
        ],
    },
    {
        id: "05",
        image: "./images/portfolio/portfolio-04.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes',
        ],
    },
    {
        id: "06",
        image: "./images/portfolio/portfolio-05.jpg",
        popupLink: [
            './images/portfolio/portfolio-05.jpg',
        ],
    },
    {
        id: "07",
        image: "./images/portfolio/portfolio-06.jpg",
        popupLink: [
            './images/portfolio/portfolio-06.jpg',
        ],
    },
    {
        id: "08",
        image: "./images/portfolio/portfolio-01.jpg",
        popupLink: [
            './images/portfolio/portfolio-01.jpg',
        ],
    },
]

const Elements = () => {
    return (
        <>
            <SEO title="Gallery || Somali Night Awards" />
            <Layout>
            <BreadcrumbOne 
                    title="Galleries"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Gallery"
                />
                <div className="main-content">
                   
                    {/* Start Elements Area  */}
                    <div className="rwt-gallery-area rn-section-gapBottom">
                        <div className="container">
                           
                            <div className="row mt_dec--30 row--15">
                                {PopupData.map((item) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}

                    {/* Start Elements Area  */}
                    {/* <div className="rwt-gallery-area rn-section-gapBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Gallery With Lightbox"
                                        title = "Gallery Style Two."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {PopupData2.map((item) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}
                    {/* End Elements Area  */}
   {/* Start Elements Area  */}
   {/* <div className="rwt-gallery-area rn-section-gapBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = ""
                                        title = "Winners"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {PopupHm.map((item) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}
                    {/* End Elements Area  */}



                </div>
            </Layout>
        </>
    )
}
export default Elements;