import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceTwo from "./ServiceTwo";
import Separator from "../separator/Separator";

const Vote = () => {
    return (
        <>

            <SEO title="Vote Now || Somali Night Awards" />
            <Layout>
                
                <div className="main-content">

           
                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Vote For Your Favoritte Category"
                                        title = "Choose Category."
                                        description = ""/>
                                </div>
                            </div>
                            <ServiceTwo 
                                cardStyle = "card-style-1"
                                textAlign = "text-start"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}

                    <Separator />

                 
                    
                </div>

            </Layout>
            
        </>
    )
}

export default Vote;
