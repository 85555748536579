import React, { useEffect, useState } from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TeamOne = ({column , teamStyle, data,CmId}) => {
  const [cmps, setCmps] = useState(data)  ;
  const [cid, setCid] = useState('');
    const [cmN, setCmN] = useState('');
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tel, setTel] = useState("");
    const handleClose = () =>{ setShow(false); setAmount('')};
    const handleShow = (id,FullName) => {
      setShow(true);
        setCid(id);
        setCmN(FullName);
      };
    const fetchInfo = async ()  => {
        setIsLoading(true);
         axios.get(process.env.REACT_APP_CMPBYCAT+CmId).then((res) =>
         setCmps(res.data)).finally(() => setIsLoading(false));
     };
  
    
    const validate = (e) => {
        e.preventDefault();
        if (!amount) return toast.error("Enter Number Of Votes");
        else {
            if (!tel ) return toast.error("Enter Phone number. e,g 6XXXXXXXXX ");
            else if (tel.length < 9 || tel.length > 9) return toast.error("Phone number must be at least 9 digits. e,g 6XXXXXXXXX");
            console.log(tel.substring(2, 0));
        }
        handleSubmit(e);
    };

    function handleSubmit(e) {
        try {
          const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());
        setIsLoading(true);
        axios.post(process.env.REACT_APP_VoteByWafi, formJson)
        .then(response => { 
            console.log(response.data);
            if(response.data.response != 'Success, Voted successful'){
                toast.error(response.data.response )  }
          else { toast.success(response.data.response ) }
            setIsLoading(false);
            handleClose();
            fetchInfo();
        })
    }catch(err) {
            setIsLoading(false);
            console.log(err);
        } 
    
        
    }
        const preventPasteNegative = (e) => {
            const clipboardData = e.clipboardData || window.clipboardData;
            const pastedData = parseFloat(clipboardData.getData('text'));
        
            if (pastedData < 0) {
                e.preventDefault();
            }
        };
        const [amount, setAmount] =useState('');
        const checkNumofVotes = (value) =>{
            if(value < 1) setAmount('');
            else setAmount(value)
        }
       
if (data.length >0) {
    return (
        <div className="row row--15">
              <ToastContainer
                    position="top-center"   autoClose={5000}   hideProgressBar={false}  newestOnTop={false}
                    closeOnClick  rtl={false}  pauseOnFocusLoss draggable pauseOnHover  theme="light"
                    />
             <Modal centered  show={show} onHide={handleClose}  backdrop="static"  keyboard={false}   >
       <Modal.Body>
       <center>  <h6 style={{color:'#000',textTransform:'capitalize'}}>vote for : {cmN}</h6></center>
      <hr style={{backgroundColor:'#000'}}></hr>
      <form method="post" onSubmit={validate}>
            <div className="form-group">
            <input 
                type="hidden" 
                name="CompetitorId"
                value={cid}
                />
                <input style={{color:'#000',border: '1px dotted #000'}}
                type="text" value={amount}
                onPaste={preventPasteNegative}
                name="NumberOfVotes"
                placeholder="Enter Number of Votes"
                maxLength="4"
                onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                 }}
               onChange={(e)=>checkNumofVotes(Number(e.target.value))}
                />
            </div>
            <h6 style={{color:'#000'}}> Pay With Evc+, Somnet, Zaad, Sahal.</h6>
            <div className="form-group">
                <input style={{color:'#000',border: '1px dotted #000'}}
                type="number" min="1"
                name="phone"
                placeholder="Enter Your Phone Number e.g 6XXXXXXXXX"
                 onChange={(e) => setTel(e.target.value)}
                />
            </div>
              <div className="form-group">
              {isLoading ?  <button disabled={true} className="btn-default btn-small">  <span> Check Your Phone,Enter Your Pin Number <span className="icon">  <FaSpinner /> </span> </span>  </button>
              :<button type="submit" className="btn-default btn-small">  Pay Now </button> }
               
            </div> 
            
        </form>
       
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
            {cmps.map((vl, index) => (
                <div className={`${column}`} key={index}>
                
                     <div className={`rn-team ${teamStyle}`}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`https://api.snawards.com/${vl.Photo}`} alt="image" />
                                </figure>
                                <figcaption className="content">
                                    <h6 className="title">{vl.FullName}</h6>
                                    <h6 className="subtitle theme-gradient">{vl.CategoryName}</h6>
                                    <span className="team-form">
                                        </span>
                                  <button id={vl.id} onClick={handleShow.bind(this, vl.id, vl.FullName)} className="btn-default btn-small">Vote Now</button>
                                    
                                </figcaption>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            ))}
        </div>
    )
            }else{
                return(<center><h3>No Competitors Yet</h3> <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br>
                </br><br></br><br></br><br></br><br></br><br></br><br></br><br></br> </center>)
            }
}


export default TeamOne;
