import React from 'react';
import Slider from "react-slick";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import CircleProgress from "../elements/progressbar/CircleProgress";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import ServiceFour from "../elements/service/ServiceFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterThree from '../common/footer/FooterThree';
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import SlipFour from "../elements/split/SlipFour";
import GalleryOne from '../elements/gallery/GalleryOne';
import ServiceTwo from '../elements/service/ServiceTwo';
var BlogListData = BlogClassicData.slice(0, 3);

const BannerData = [
    {
        image: "images/bg/slide-1.jpg",
        title: "Somali Night Awards.",
        description: "is idea from Somali intellectual youth who desire participation and developing business."
    },
    {
        image: "images/bg/slide-2.jpg",
        title: "Somali Night Awards.",
        description: "is an award ceremony honoring the greatest achievements of the Somali community."
    },
    {
        image: "images/bg/slide-3.jpg",
        title: "Somali Night Awards.",
        description: " promises to be one of the most important ceremonies of its kind ever staged."
    },
]

const PopupHm = [
    {
        id: "01",
        image: "./images/portfolio/w1.png",
        popupLink: [
            './images/portfolio/w1.png',
           
        ],
    },
    {
        id: "02",
        image: "./images/portfolio/w2.png",
        popupLink: [
            './images/portfolio/w2.png',
        ],
    },
    {
        id: "03",
        image: "./images/portfolio/w3.png",
        popupLink: [
            './images/portfolio/w3.png',
        ],
    },
    {
        id: "04",
        image: "./images/portfolio/w4.png",
        popupLink: [
            './images/portfolio/w4.png',
        ],
    },
]

const Startup = () => {
    return (
        <>
            <SEO title="Home" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-950 bg-overlay bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="col-lg-12">
                                            <div className="inner text-center">
                                                <h1 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h1>
                                                <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                                {/* <div className="button-group mt--30">
                                                    <a className="btn-default btn-large round" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">Get started now</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}


                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-split-area rn-section-gap">
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = ""
                                        title = "Objectives"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <SlipFour />
                        </div>
                    </div>
                    {/* End Elements Area  */}
                    <div className="rwt-gallery-area rn-section-gapBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = ""
                                        title = "Winners"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {PopupHm.map((item) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}


                <Separator />
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = ""
                                        title = " Voting Process."
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />
                  {/* Start Service Area  */}
                  <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Vote For Your Favoritte Category"
                                        title = "Choose Category."
                                        description = "Click a Category To See It's Competitors."
                                     />
                                </div>
                            </div>
                            <ServiceTwo 
                                cardStyle = "card-style-1"
                                textAlign = "text-start"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}

                    <Separator />
                <Separator />
                  {/* Start Elements Area  */}
   
                <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Latests News"
                                    title = "Our Latest News."
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> 

                <FooterThree />

            </main>
        </>
    )
}

export default Startup;
