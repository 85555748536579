import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import GalleryOne from "./GalleryOne";

const w2021 = [
    {
        id: "01",
        image: "./images/winners/2021/1.png",
        popupLink: [
            './images/winners/2021/1.png',
           
        ],
    },
    {
        id: "02",
        image: "./images/winners/2021/2.png",
        popupLink: [
            './images/winners/2021/2.png',
        ],
    },
    {
        id: "03",
        image: "./images/winners/2021/3.png",
        popupLink: [
            './images/winners/2021/3.png',
        ],
    },
    {
        id: "04",
        image: "./images/winners/2021/4.png",
        popupLink: [
            './images/winners/2021/4.png',
        ],
    },
    {
        id: "05",
        image: "./images/winners/2021/5.png",
        popupLink: [
            './images/winners/2021/5.png',
        ],
    },
    {
        id: "06",
        image: "./images/winners/2021/6.png",
        popupLink: [
            './images/winners/2021/6.png',
        ],
    },
    {
        id: "07",
        image: "./images/winners/2021/7.png",
        popupLink: [
            './images/winners/2021/7.png',
        ],
    },
    {
        id: "08",
        image: "./images/winners/2021/8.png",
        popupLink: [
            './images/winners/2021/8.png',
        ],
    },
    {
        id: "09",
        image: "./images/winners/2021/9.png",
        popupLink: [
            './images/winners/2021/9.png',
        ],
    },
    {
        id: "010",
        image: "./images/winners/2021/10.png",
        popupLink: [
            './images/winners/2021/10.png',
        ],
    },
    {
        id: "011",
        image: "./images/winners/2021/11.png",
        popupLink: [
            './images/winners/2021/11.png',
        ],
    },
    {
        id: "012",
        image: "./images/winners/2021/12.png",
        popupLink: [
            './images/winners/2021/12.png',
        ],
    },
    {
        id: "013",
        image: "./images/winners/2021/13.png",
        popupLink: [
            './images/winners/2021/13.png',
        ],
    },
    {
        id: "014",
        image: "./images/winners/2021/14.png",
        popupLink: [
            './images/winners/2021/14.png',
        ],
    },
    {
        id: "015",
        image: "./images/winners/2021/15.png",
        popupLink: [
            './images/winners/2021/15.png',
        ],
    },
    {
        id: "016",
        image: "./images/winners/2021/16.png",
        popupLink: [
            './images/winners/2021/16.png',
        ],
    },
    {
        id: "017",
        image: "./images/winners/2021/17.png",
        popupLink: [
            './images/winners/2021/17.png',
        ],
    },
]

const w2022 = [
    {
        id: "01",
        image: "./images/winners/2022/1.png",
        popupLink: [
            './images/winners/2022/1.png',
           
        ],
    },
    {
        id: "02",
        image: "./images/winners/2022/2.png",
        popupLink: [
            './images/winners/2022/2.png',
        ],
    },
    {
        id: "03",
        image: "./images/winners/2022/3.png",
        popupLink: [
            './images/winners/2022/3.png',
        ],
    },
    {
        id: "04",
        image: "./images/winners/2022/4.png",
        popupLink: [
            './images/winners/2022/4.png',
        ],
    },
    {
        id: "05",
        image: "./images/winners/2022/5.png",
        popupLink: [
            './images/winners/2022/5.png',
        ],
    },
    {
        id: "06",
        image: "./images/winners/2022/6.png",
        popupLink: [
            './images/winners/2022/6.png',
        ],
    },
    {
        id: "07",
        image: "./images/winners/2022/7.png",
        popupLink: [
            './images/winners/2022/7.png',
        ],
    },
    {
        id: "08",
        image: "./images/winners/2022/8.png",
        popupLink: [
            './images/winners/2022/8.png',
        ],
    },
   
]

const w2023 = [
    {
        id: "01",
        image: "./images/winners/2023/1.png",
        popupLink: [
            './images/winners/2023/1.png',
           
        ],
    },
    {
        id: "02",
        image: "./images/winners/2023/2.png",
        popupLink: [
            './images/winners/2023/2.png',
        ],
    },
    {
        id: "03",
        image: "./images/winners/2023/3.png",
        popupLink: [
            './images/winners/2023/3.png',
        ],
    },
    {
        id: "04",
        image: "./images/winners/2023/4.png",
        popupLink: [
            './images/winners/2023/4.png',
        ],
    },
    {
        id: "05",
        image: "./images/winners/2023/5.png",
        popupLink: [
            './images/winners/2023/5.png',
        ],
    },
    {
        id: "06",
        image: "./images/winners/2023/6.png",
        popupLink: [
            './images/winners/2023/6.png',
        ],
    },
    {
        id: "07",
        image: "./images/winners/2023/7.png",
        popupLink: [
            './images/winners/2023/7.png',
        ],
    },
   
]

const Elements = () => {
    return (
        <>
            <SEO title="Winners || Somali Night Awards" />
            <Layout>
                <BreadcrumbOne 
                    title="Somali Night Award Winners."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Winners"
                />
                <div className="main-content">
                  
                    {/* Start Elements Area  */}
                    <div className="rwt-gallery-area rn-section-gapBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "2023"
                                        title = "Winners Of 2023."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {w2023.map((item) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}

   {/* Start Elements Area  */}
   <div className="rwt-gallery-area rn-section-gapBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "2022"
                                        title = "Winners Of 2022."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {w2022.map((item) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}
                       {/* Start Elements Area  */}
                       <div className="rwt-gallery-area rn-section-gapBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "2021"
                                        title = "Winners Of 2021."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {w2021.map((item) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}

                </div>
            </Layout>
        </>
    )
}
export default Elements;